import Loading from "../../common/Loading";
import React, {useContext, useEffect, useState} from "react";
import ErrorView from "../../common/ErrorView";
import {apiContext} from "../../ApiContext";
import AccountSummaryView from "./AccountSummaryView";
import AccountSummaryPartialFailureView from "./AccountSummaryPartialFailureView";

function OnboardSummaryContainer({applicationId, consumptionId, redirectUrl}){
  const [isLoading, setLoading] = useState(true)
  const [isError, setError] = useState(false)
  const [accounts, setAccounts] = useState([])
  const api = useContext(apiContext)

  const getApplicationSummary = async () => {
    try {
      const data = await api.fetchOnboardSummary(applicationId, consumptionId);
      setAccounts(data.accountResults)
    } catch (err) {
        setError(true);
    }
    setLoading(false)
  }

  const setAccountStatus = (feedCreated, errorMessage) => feedCreated? "Success" : errorMessage

  const renderAccountSummary = () => {
    if(accounts.length === 0)
      return <ErrorView type = 'applicationResultFailed' redirectUrl={redirectUrl}/>

  const areAllFailure = () => {
      return accounts.every(account => !account.feedCreated);
    }
  const areAllSuccess = () => {
      return accounts.every(account => account.feedCreated);
    }


  const accountSummary = accounts.map(account => ({
      name: account.name,
      type: account.type,
      number: account.number,
      status: setAccountStatus(account.feedCreated, account.errorMessage)
    }))


    if (areAllFailure() || areAllSuccess())
      return <AccountSummaryView
        accountData={accountSummary}
        redirectUrl={redirectUrl}
        resultStatus={areAllSuccess()? "success":"failure"}
      />
    else
      return <AccountSummaryPartialFailureView
        accountData={accountSummary}
        redirectUrl={redirectUrl}
      />
  }

  useEffect(() => {
    getApplicationSummary()
  }, [])

  return (
    <div>
      {
        isError ? <ErrorView type = 'somethingWentWrong' redirectUrl={redirectUrl}/> :
          isLoading ?
            <Loading message={"Fetching your onboarding summary..."}/> :
            renderAccountSummary()
      }
    </div>
  )
}

export default OnboardSummaryContainer;
