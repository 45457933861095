import React from "react";
import {Button, ButtonRow, Card } from "@myob/myob-widgets";
import "../../common/Common.css"
import AccountResultTable from "./components/AccountResultTable";
import AccountSummaryPageState from "./components/AccountSummaryPageState";

function AccountSummaryView ({accountData, redirectUrl, resultStatus}) {
  const handleOnClick = () => {
    window.location.href = redirectUrl
  }

  return (
    <div className='page-block animate'>
      <Card size={'xxl'}>
        {
          <AccountSummaryPageState
            resultStatus={resultStatus}
          />
        }
        {
          <AccountResultTable
            accountData={accountData}

          />
        }
      </Card>
      <ButtonRow className="animate">
        <Button
          className="finish-summary"
          style={{marginTop:'20px'}}
          type="primary"
          data-testid="return to bank feeds page button"
          onClick={handleOnClick}>
            Return to bank feeds page
        </Button>
      </ButtonRow>
    </div>
  );
}

export default AccountSummaryView;
