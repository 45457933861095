import * as _ from "lodash";
import {ErrorLgIcon, Heading, SuccessIcon, Text, Box} from "@myob/myob-widgets";
import ResultTable from "./ResultTable";
import React from "react";

export default function TableWithHeader({
                                      tableData,
                                      showStatusColumn,
                                      headText,
                                      headStyle,
                                      additionalInfo,
                                      type,
                                      cellSelector = null,
                                      width='50%'
                                    }) {

  function renderCellContent({name, number}) {
    return <>
      <Text fontWeight="medium" style={{marginBottom: 0, whiteSpace: 'normal', wordWrap: 'break-word'}}>
      {name}
     </Text>
      <Text tone="neutralStronger" style={{marginBottom: 0, whiteSpace: 'normal', wordWrap: 'break-word'}}>
        {number}
      </Text></>;
  }

  function renderCell(row) {
    if (!!cellSelector) {
      return cellSelector(row)
    }
    return renderCellContent(row)
  }

  function renderStatusCell(row) {
    return row.matched ? (
        <SuccessIcon color="success" size="lg" style={{marginTop:'8px'}}/>
      ) : (
        <ErrorLgIcon color="danger" size="lg" style={{marginTop:'8px'}}/>
      )
  }
  const tableConfiguration = {
    bankAccount: {
      columns: [
        {
          key: 'myobAccount',
          description: 'MYOB account',
          width: width,
          cellSelector: (row) => {
            return  renderCellContent({name: row['accountName'], number:row['accountNumber']})}
        },
        { key: 'bankAccount',
          description: 'BNZ bank account',
          width: width,
          cellSelector: (row) => renderCell(row)
        },
        showStatusColumn && {key: 'status', description: '', width: '10%', cellSelector: (row) => renderStatusCell(row)}
      ].filter(Boolean),
      placeholder: "Select BNZ account",
    },
    creditCard: {
      columns: [
        {key: 'myobCreditCard',
          description: 'MYOB credit card',
          width: width,
          cellSelector: (row) => renderCellContent({name: row['cardName'], number: row['cardNumber']})
        },
        {key: 'creditCard',
          description: 'BNZ credit card',
          width: width,
          cellSelector: (row) => renderCell(row)
        },
        showStatusColumn && {key: 'status', description: '', width: '10%', cellSelector: (row) => renderStatusCell(row)},
      ].filter(Boolean),
      placeholder: "Select BNZ credit card"
    }
  }

  return !_.isEmpty(tableData) && (<div className="table-with-header-section" >
    <Box display="flex" style={headStyle}>
      <Heading level="3" style={{fontWeight: '500'}}>{headText}</Heading>
      {additionalInfo}
    </Box>
    <ResultTable
      tableColumns={tableConfiguration[type].columns}
      tableData={tableData}
      />
  </div>);
}
