import {Button, Modal, Text, TextLink} from "@myob/myob-widgets";
import React from "react";

export default function ConfirmationModal({setShowMigrationModal, isFromBNZ, handleMigrate}) {
  return <Modal size="large"
                style={{width: '564px', top: 'calc(50vh - 221px)'}}
                title="Have you checked these reconnected accounts match?"
                onCancel={() => setShowMigrationModal(false)}
  >
    <Modal.Body>
      <p>
        Continuing means accounts matched incorrectly will need to be manually changed after.
        {isFromBNZ && (
          <Text style={{marginTop: '16px'}}>
            <TextLink tone="info"
                      href="https://www.myob.com/nz/support/myob-business/banking/bank-feeds/setting-up-bank-feeds/reconnect-your-BNZ-bank-feed-accounts?productview=Browser#cant-choose-the-account-you-want-to-reconnect"
                      target="_blank">
              Learn about account access
            </TextLink>
          </Text>
        )}
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button
        tone="neutral" variant="standard"
        className="btn btn-default"
        onClick={() => setShowMigrationModal(false)}
      >
        Go back
      </Button>
      <Button
        className="btn btn-primary"
        onClick={handleMigrate}
      >
        Reconnect accounts
      </Button>
    </Modal.Footer>
  </Modal>;
}
