import React, {useState, useEffect} from "react";
import {Button, ButtonRow, Card, PageHead, StickyHeader} from "@myob/myob-widgets";
import SelectionMessage from "./components/SelectionMessage";
import AccountsTable from "./components/AccountsTable";
import {FullStory} from "@fullstory/browser";

function SelectAccountsContainer(
  {
    accounts,
    bankfeedLimit,
    availableFeeds,
    productRedirectUrl,
    subscriptionUpgradeUrl,
    onSubmit,
    source,
  }
) {
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isOverSelectionLimit, setIsOverSelectionLimit] = useState(false);

  const handleOnClick = () => {
    onSubmit(selectedAccounts);
  }

  const handleCancel = () => {
    window.location.href = productRedirectUrl;
  }

  const handleUpgrade = () => {
    window.open(subscriptionUpgradeUrl, '_blank').focus()
  }

  const handleManageFeed = () => {
    if (productRedirectUrl) {
      window.open(productRedirectUrl, '_blank').focus()
    }
  }

  const updateButtonStatus = () => {
    const accountsSelected = selectedAccounts.length > 0;
    const underFeedLimit = selectedAccounts.length <= availableFeeds || availableFeeds == null;
    if(accountsSelected && underFeedLimit) {
      return setIsButtonDisabled(false)
    }
    setIsButtonDisabled(true)
  }

  const checkSelectionLimit = () => {
    const overFeedLimit = selectedAccounts.length > availableFeeds;
    if(overFeedLimit) {
      return setIsOverSelectionLimit(true)
    }
    setIsOverSelectionLimit(false)
  }

  useEffect(() => {
    FullStory('trackEvent', {
      name: 'Onboard Finalise',
      properties: {
        source: source
      },
    })
  }, []);

  useEffect(() => {
    updateButtonStatus()
    checkSelectionLimit()
  }, [selectedAccounts]);

  return(
    <div className={'page-block--sm animate'} style={{ padding: '2.4rem' }}>
      <PageHead title="Select accounts" />
      <StickyHeader>
        <SelectionMessage
          availableFeeds={availableFeeds}
          bankfeedLimit={bankfeedLimit}
          isOverFeedLimit={isOverSelectionLimit}
          handleManageFeed={handleManageFeed}
          handleUpgrade={handleUpgrade}
          source={source}
        />
      </StickyHeader>
      <Card size={'xxl'}>
        <AccountsTable
          accounts={accounts}
          selectedAccounts={selectedAccounts}
          setSelectedAccounts={setSelectedAccounts}
        />
      </Card>
      <ButtonRow className="animate">
        { productRedirectUrl && <Button
          className="cancel-account-selection"
          style={{marginBottom: '20px'}}
          type="secondary"
          onClick={handleCancel}
        >
          Cancel
        </Button>}
        <Button
          style={{marginBottom: '20px'}}
          type="primary"
          tone="brand"
          onClick={handleOnClick}
          disabled={isButtonDisabled}
        >
          Connect
        </Button>
      </ButtonRow>
    </div>
  )
}

export default SelectAccountsContainer;
