import {Alert} from "@myob/myob-widgets";
import React from "react";

export default function AlertSection(isRetryLoading, migratedResultCount, unmatchedBankAccountCount, unmatchedCreditCardCount, allSuccess, failureCount) {
  if (isRetryLoading || migratedResultCount === 0) return null;
  if (!allSuccess)
    return (<Alert tone="danger" inline>
      {failureCount} accounts failed to reconnect. Please try to connect again, if the issue persists try again at another time.
    </Alert>)

  if (unmatchedBankAccountCount > 0 || unmatchedCreditCardCount > 0)
    return (<Alert tone="success" inline>
      {migratedResultCount} bank feeds were successfully reconnected, {unmatchedBankAccountCount + unmatchedCreditCardCount} bank feeds were skipped. Reconnected MYOB bank feeds will continue to work as usual.
    </Alert>)

  return <Alert tone="success" inline>
    {migratedResultCount} bank feeds were successfully reconnected. Reconnected MYOB bank feeds will continue to work as usual.
  </Alert>
}
