import React from "react";
import HelpUrls from "../../../HelpUrls";
import success from "../../../assets/success.svg";
import crossFail from "../../../assets/cross-fail.svg";
import { PageState } from "@myob/myob-widgets";

const AccountSummaryPageState = ({resultStatus}) => {
  const resultStatuses = {
    success: {
      title: "Your bank feeds are connected",
      description: (<p>
        You can link your feeds on the manage bank feeds page.
        If you don't see any active feeds there, wait a few minutes and reload the page.
        For help, <a href={HelpUrls.HowToSetupBankFeedUrl} target="_blank" rel="noreferrer">view how to set up bank feeds</a>.
      </p>),
      img: success,
      alt: "success"
    },
    failure: {
      title: "We couldn't connect your accounts",
      description: (<p>
        There was a problem with the account details or the type of account isn't eligible for bank feeds.
        <br/><br/>
        <a href={HelpUrls.HowToFixBankFeedConnectionErrorUrl} target="_blank" rel="noreferrer">Learn more about the errors and how to fix them</a>.
      </p>),
      img: crossFail,
      alt: "error"
    }
  }

  return (<PageState
    title={resultStatuses[resultStatus].title}
    className='img-block'
    description={resultStatuses[resultStatus].description}
    image={
      <img
        src={resultStatuses[resultStatus].img}
        alt={resultStatuses[resultStatus].alt}
      />
    }
  />)
}

export default AccountSummaryPageState;
