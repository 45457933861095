import {ButtonLink, Card, RefreshIcon} from "@myob/myob-widgets";
import * as _ from "lodash";
import React from "react";
import TableSection from "./TableSection";

export default function MatchedResultCard({
                                    migratedResult,
                                    handleRetryClick,
                                    bankAccountTableData,
                                    creditCardTableData,
                                  }) {
  const failureCount = _.chain(migratedResult).values().filter(value => !value).size().value()

  const bankAccountData = {
    headText: "Matched bank accounts",
    headStyle: {fontWeight: "500", marginTop: '24px', justifyContent:"space-between"},
    additionalInfo: failureCount > 0 && (
      <ButtonLink icon={<RefreshIcon/>} inline onClick={handleRetryClick}>Retry failed connections</ButtonLink>),
    tableData: bankAccountTableData,
    width: '45%',
  }

  const creditCardData = {
    headText: "Matched credit cards",
    headStyle: {fontWeight: "500", marginTop: '24px', justifyContent:"space-between"},
    tableData: creditCardTableData,
    width: '45%',
    additionalInfo: failureCount > 0 && (
      (failureCount > 0 && _.isEmpty(bankAccountData.tableData)) && (
        <ButtonLink icon={<RefreshIcon/>} inline onClick={handleRetryClick}>Retry failed connections</ButtonLink>))
  }

  return <Card>
    <TableSection
    showStatusColumn={true}
    bankAccountData={bankAccountData}
    creditCardData={creditCardData} />
  </Card>
}
