import {Alert, Separator, Text, TextLink} from "@myob/myob-widgets";
import React from "react";

export default function MigrationDescription({isFromBNZ}) {
  return <>
    <Text tone="neutralStrongest" fontSize="md">
      To reconnect accounts, first match your BNZ account on the right to your MYOB account on the left.
    </Text>
    <Separator/>
    {isFromBNZ && (<Alert tone="info" inline>
      <p><b>Can't see the correct bank account?</b> You may not have access to the relevant accounts.
        <TextLink style={{marginLeft: '4px'}} tone="info"
                  href="https://www.myob.com/nz/support/myob-business/banking/bank-feeds/setting-up-bank-feeds/reconnect-your-BNZ-bank-feed-accounts?productview=Browser#cant-choose-the-account-you-want-to-reconnect"
                  target="_blank">
          Learn about account access
        </TextLink></p>
    </Alert>)}
  </>;
}

