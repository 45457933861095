import {Card, Text} from "@myob/myob-widgets";
import React from "react";
import TableSection from "./TableSection";

export default function NotMatchedResultCard({
                                       bankAccountTableData,
                                       creditCardsTableData,
                                     }) {

  const bankAccountData = {
    headText: "Unmatched bank accounts",
    headStyle: {marginTop: '24px', flexDirection: "column"},
    additionalInfo: <Text tone="foregroundDefault" style={{fontWeight: '400', marginTop: '8px'}}>
      Can't reconnect the following bank accounts. If you can't see a relevant account, check you have the right bank access to view these accounts.
    </Text>,
    width: '45%',
    tableData: bankAccountTableData,
    cellSelector:  () => (<Text style={{ marginBottom: 0, whiteSpace: 'normal', wordWrap: 'break-word' }}>Not Matched</Text>)
  }

  const creditCardData = {
    headText: "Unmatched credit cards",
    headStyle: {marginTop: '24px', flexDirection: "column"},
    additionalInfo: <Text tone="foregroundDefault" style={{fontWeight: '400', marginTop: '8px'}}>
      Can't reconnect the following credit cards. If you can't see a relevant card, check you have the right bank access to view these cards.
    </Text>,
    width: '45%',
    tableData: creditCardsTableData,
    cellSelector:  () => (<Text style={{ marginBottom: 0, whiteSpace: 'normal', wordWrap: 'break-word' }}>Not Matched</Text>)
  }

  return <Card style={{ marginTop: '24px' }}>
    <TableSection
      showStatusColumn={false}
      bankAccountData={bankAccountData}
      creditCardData={creditCardData}
    />
  </Card>
}
