import React, {useState} from "react";
import ErrorView from "../common/ErrorView";

function RootView() {
  const [isError, setError] = useState(false);
  const stateInHash = window.location.hash?.match(/state=([a-zA-Z0-9-]+)/)

  const getState = () => {
    return stateInHash[1]
  }

  const redirectToPreviousPage = () => {
    try {
      const state = getState()
      const loginState = JSON.parse(localStorage.getItem("login.state"))
      const redirectUri = loginState[state]['redirectUri']
      window.location.assign(redirectUri)
    } catch {
      setError(true)
    }
  }

  return (
    <div>
      { stateInHash?.length > 1 && !isError ?
        redirectToPreviousPage() : <ErrorView type='notFound'/>
      }
    </div>
  );
}

export default RootView;
