import {Card, PageState, InfoIcon} from "@myob/myob-widgets";
import React from "react";

const ConsentManagementSuccessView = () => {
  const renderTitle = () => (<div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left'
  }}>
    <InfoIcon color="info" style={{marginRight: 8}}/>
    {"You're all done"}
  </div>);
  return (<div className={'page-block'}>
    <Card>
      <PageState
        className={'img-block'}
        title={renderTitle()}
        description={
          <React.Fragment>
            You can close this window.
          </React.Fragment>
        }
      >
      </PageState>
    </Card>
  </div>)
}

export default ConsentManagementSuccessView;
