import OpenBankingAccountSummaryPageState from "./OpenBankingAccountSummaryPageState";
import OpenBankingAccountResultTable from "./OpenBankingAccountResultTable";
import React from "react";
import * as _ from 'lodash'

function OpenBankingAccountSummary({resultStatus, accounts }) {
  return <>
    <OpenBankingAccountSummaryPageState
      resultStatus={resultStatus}
      selectedAccountsNumber={accounts?.length}
    />
    {!_.isEmpty(accounts) &&
    (<OpenBankingAccountResultTable
      accountsData={accounts}
    />)}
  </>;
}

export default OpenBankingAccountSummary;
