import React from "react";
import {Button, ButtonRow, Card, PageState, Table} from "@myob/myob-widgets";
import "../../../common/Common.css"

function FeedConfirmationView ({redirectUrl, accountData}) {

  const tableColumns = [
    { key: 'name', description: 'Account Name', visible: true, align: 'center' },
    { key: 'number', description: 'Account Number', visible: true, align: 'center' },
    { key: 'type', description: 'Account Type', visible: true, align: 'center' },
  ];

  const handleOnClick = () => {
    window.location.href = redirectUrl
  }

  const hasNewlyOnboardedAccounts = () => {
    return accountData.length > 0;
  }

  const description = hasNewlyOnboardedAccounts() ?
    'The following accounts are now linked to MYOB'  :
    'All of your available accounts have been linked with MYOB!'

  return (
    <div className='page-block animate'>
      <Card size={'xxl'}>
        <PageState
          title="Application connected"
          className='img-block'
          description={description}
          image={
            <img
              src="https://feelix-assets.s3-ap-southeast-2.amazonaws.com/page-state/success-state/success.svg"
              alt="success"
            />
          }
        />
        {
          hasNewlyOnboardedAccounts() &&
          <Table
            className='animate'
            columns={tableColumns}
            data={accountData}
          />
        }
      </Card>
      <ButtonRow className="animate">
        <Button
          className="finish-confirmation"
          style={{marginBottom:'20px'}}
          type="primary"
          data-testid="manage bank feeds button"
          onClick={handleOnClick}>
          Manage bank accounts
        </Button>
      </ButtonRow>
    </div>
  );
}

export default FeedConfirmationView;
