import * as httpApi from "./mappings/HttpApiMapping"
import * as memoryApi from "./mappings/MemoryApiMapping"

const environment = process.env.REACT_APP_ENV;
let api = httpApi;

if(environment === 'development') {
  api = memoryApi
}

export {api};

