import TableWithHeader from "./TableWithHeader";
import React from "react";

export default function TableSection({bankAccountData, creditCardData, showStatusColumn}) {
  return <>
    <TableWithHeader
      type={"bankAccount"}
      showStatusColumn={showStatusColumn}
      {...bankAccountData}
    />
    <TableWithHeader
      type={"creditCard"}
      showStatusColumn={showStatusColumn}
      {...creditCardData}
    />
  </>;
}
