import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from '@myob/myob-widgets';
import { init as initFullStory } from '@fullstory/browser';

initFullStory({ orgId: 'o-1XYR1G-na1' });

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme='classic'>
        <App />
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
