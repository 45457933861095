import React from "react";
import {Button, ButtonRow, Card} from "@myob/myob-widgets";
import "../../common/Common.css"
import AccountSummaryPageState from "./components/AccountSummaryPageState";
import AccountResultTable from "./components/AccountResultTable";

function AccountSummaryPartialFailureView ({accountData,redirectUrl}) {
  const handleOnClick = () => {
    window.location.href = redirectUrl
  }

  const successfulAccountData = accountData.filter(account => account.status === "Success")
  const unSuccessfulAccountData = accountData.filter(account => account.status !== "Success")

  return (
    <div className='page-block animate'>
      <Card size={'md'}>
        {
          <AccountSummaryPageState
            resultStatus="success"
          />
        }
        {
          <AccountResultTable
            accountData={successfulAccountData}
          />
        }
        {
          <AccountSummaryPageState
            resultStatus="failure"
          />
        }
        {
          <AccountResultTable
            accountData={unSuccessfulAccountData}
          />
        }
        </Card>
      <ButtonRow className="animate">
        <Button
          className="finish-summary-with-partial-failure"
          style={{marginTop:'20px'}}
          type="primary"
          data-testid="return to bank feeds page button"
          onClick={handleOnClick}>
            Return to bank feeds page
        </Button>
      </ButtonRow>
    </div>
  );
}

export default AccountSummaryPartialFailureView;
