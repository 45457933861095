import { PageState, Spinner } from "@myob/myob-widgets";
import React from "react";

function Loading({message}) {
  return (
    <PageState
      title={message}
      image={<Spinner size="medium" />}
    >
    </PageState>
  );
}

export default Loading;
