import React from "react";
import {Label, Table} from "@myob/myob-widgets";

function AccountResultTable ({accountData}) {
  const tableColumns = [
    { key: 'type', description: 'Account Type', visible: true, align: 'center'},
    { key: 'name', description: 'Account Name', visible: true, align: 'center', textWrap:"wrap"},
    { key: 'number', description: 'Account Number', visible: true, align: 'center' },
    { key: 'status', description: 'Status', visible: true, align: 'center' },
  ];

  const setLabel = (status) => {
    if(status === "Success")
      return <Label type="boxed" size="sm" tone="success">Success</Label>
    else if(status === "LimitExceeded")
      return <Label type="boxed" size="sm" tone="warning">Limit exceeded</Label>
    return <Label type="boxed" size="sm" tone="accent">Failed to connect</Label>
  }

  const setLabelOnStatus = (accountData) => {
    return accountData.map(account =>
      {
        account.status = setLabel(account.status)
        return account
      }
    )
  }

  return(
    <Table
      className='animate'
      columns={tableColumns}
      data={setLabelOnStatus(accountData)}
    />
  )
}

export default AccountResultTable;
