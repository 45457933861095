import {Table} from "@myob/myob-widgets";
import React from "react";
import * as _ from "lodash";

//replacement of ReconnectTable.js
const ResultTable = ({
                       tableColumns,
                       tableData
                     }) => {
  return (
    <Table>
      <Table.Header>
        {_.map(tableColumns, column => (
          <Table.HeaderItem key={column.key} width={column.width}>
            {column.description}
          </Table.HeaderItem>))}
      </Table.Header>
      <Table.Body>
        {_.map(tableData, row => (
          <Table.Row key={row.id}>
            {tableColumns.map(column => (
              <Table.RowItem key={`${row.id}-${column.key}`} columnName={column.description} width={column.width} textWrap="wrap" valign="middle">
                {column.cellSelector(row)}
              </Table.RowItem>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

export default ResultTable;
