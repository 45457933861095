import React from "react";
import {Checkbox, Label, Table} from "@myob/myob-widgets";
import * as _ from 'lodash';

function AccountsTable({accounts, selectedAccounts, setSelectedAccounts}){
  const showConnectedColumn = _.some(accounts, a => a.onboarded);

  const tableColumns =
    [
      {key: 'name', description: 'Account name', visible: true},
      {key: 'number', description: 'Account number', visible: true},
      {key: 'type', description: 'Account type', visible: true},
      {key: 'connected', description: '', visible: showConnectedColumn},
    ];

  _.forEach(accounts, (account, index) => account.id = index.toString())

  const selectableAccounts = _.filter(accounts, d => !d.onboarded);

  const isAllChecked = selectedAccounts.length > 0 &&
    selectedAccounts.length === selectableAccounts.length

  const selectAll = () =>
    setSelectedAccounts(prev => prev.length === selectableAccounts.length ? [] : selectableAccounts);

  const selectItem = (item) =>
    setSelectedAccounts(prev => !_.includes(prev, item) && !item.onboarded ?
      [...prev, item] : _.filter(prev, account => account.id !== item.id));

  const onRowSelect = (id) => selectItem(accounts[id]);

  const renderHeader = () => (
    <Table.Header>
      <Table.HeaderItem width="auto" key="checkbox">
        <Checkbox
          name="bulk-select"
          label="Bulk select"
          hideLabel
          onChange={selectAll}
          checked={isAllChecked}
          indeterminate={selectedAccounts.length > 0 && !isAllChecked}
        />
      </Table.HeaderItem>
      {tableColumns.map(c => c.visible && (
        <Table.HeaderItem key={c.key}>
          {c.description}
        </Table.HeaderItem>
      ))}
    </Table.Header>
  );

  const renderRow = (a, index) => (
    <Table.Row
      key={a.id}
      isSelected={_.includes(selectedAccounts, a)}
      onRowSelect={onRowSelect}
      rowData={a}
      isInactive={a.onboarded}
    >
      <Table.RowItem width="auto" cellRole="checkbox" valign="middle">
        <Checkbox
          name={`${a.id}-select`}
          label={`Select row ${index}`}
          hideLabel
          onChange={() => selectItem(a)}
          checked={_.includes(selectedAccounts, a)}
          disabled={a.onboarded}
        />
      </Table.RowItem>
      <Table.RowItem columnName="Account name" textWrap="wrap" valign="middle">{a.name}</Table.RowItem>
      <Table.RowItem columnName="Account number"  textWrap="wrap" valign="middle" >{a.number}</Table.RowItem>
      <Table.RowItem columnName="Account type" valign="middle">{a.type}</Table.RowItem>
      {showConnectedColumn &&
        (<Table.RowItem valign="middle">
          <Label tone="brand" type="boxed" size="sm">
            {a.onboarded ? 'Connected' : ''}
          </Label>
        </Table.RowItem>)
      }
    </Table.Row>
  );

  return(
    <Table onRowSelect={onRowSelect}>
      {renderHeader()}
      <Table.Body onRowSelect={onRowSelect}>
        { _.map(accounts, renderRow) }
      </Table.Body>
    </Table>
  )
}

export default AccountsTable
