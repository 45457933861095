import * as _ from "lodash";
import {api} from "../../../Dependencies";
import {Button, ButtonRow, Card, PageHead, useToast} from "@myob/myob-widgets";
import MigrationDescription from "./MigrationDescription";
import ToMigrateSection from "./ToMigrateSection";
import ConfirmationModal from "./ConfirmationModal";
import DoItLaterModal from "./DoItLaterModal";
import React, {useRef, useState} from "react";

export const SKIP_FOR_NOW_OPTION = {name: 'Skip for now >', number: ''};

const FeedMigrationView = ({
      accountData,
      isLoading,
      setLoading,
      setFeedMigrated,
      isFromBNZ,
      setError,
      handleRedirect,
      setResultAndSelections
    }) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [showMigrationModal, setShowMigrationModal] = useState(false);
  const [showDoItLaterModal, setShowDoItLaterModal] = useState(false);
  const migrateSelectionRef=useRef({});
  const toast = useToast();

  const filterComboboxItems = (allItems, selectedItems, currentRowId) =>
    _.filter(allItems, item =>
      _.isEmpty(item.number) || !_.some(selectedItems, (selected, key) => selected?.number === item.number && key !== currentRowId)
    );

  const validateSelections = () => {
    let hasError = false;
    let allSkipped = true;
    const newValidationErrors = {};

    const bankAccountSelections = migrateSelectionRef.current?.bankAccountSelections;
    const creditCardSelections = migrateSelectionRef.current?.creditCardSelections;

    const bankAccounts = accountData?.bankFeedsForMigration?.bankAccounts || [];
    const creditCards = accountData?.bankFeedsForMigration?.creditCards || [];

    bankAccounts.forEach(account => {
      const selection = bankAccountSelections[account.id];
      if (!selection) {
        newValidationErrors[account.id] = "Make a selection before continuing";
        hasError = true;
        allSkipped = false;
      } else if (selection !== SKIP_FOR_NOW_OPTION) {
        allSkipped = false;
      }
    });

    creditCards.forEach(card => {
      const selection = creditCardSelections[card.id];
      if (!selection) {
        newValidationErrors[card.id] = "Make a selection before continuing";
        hasError = true;
        allSkipped = false;
      } else if (selection !== SKIP_FOR_NOW_OPTION) {
        allSkipped = false;
      }
    });

    setValidationErrors(newValidationErrors);
    return {allValid: !hasError, allSkipped};
  };

  const handleSaveSelection = async () => {
    const {allValid, allSkipped} = validateSelections();

    if (allValid) {
      if (allSkipped) {
        toast({
          message: 'Please reconnect at least 1 bank account or credit card',
          tone: 'warning',
          close: 10000
        });
      } else {
        setShowMigrationModal(true);
      }
    }
  };

  const handleMigrate = async () => {
    try {
      const bankAccountSelections = migrateSelectionRef.current?.bankAccountSelections;
      const creditCardSelections = migrateSelectionRef.current?.creditCardSelections;
      const accounts = _.chain({})
        .assign(bankAccountSelections, creditCardSelections)
        .toPairs()
        .filter((it) => !!it[1] && !_.isEmpty(it[1].number))
        .map((it) => ({feedId: it[0], account: it[1]}))
        .value();
      if (!_.isEmpty(accounts) && !isLoading) {
        setLoading(true);
        setShowMigrationModal(false);

        const result = await api.migrateFeeds(accountData.applicationId, accounts);

        setResultAndSelections({migratedResult: result, bankAccountSelections, creditCardSelections})
        setFeedMigrated(true);
        setLoading(false);
      }
    } catch (err) {
      setError(true);
      setLoading(false);
    }
  }

  return (
    <div className={'page-block--sm animate'} style={{padding: '2.4rem', minWidth: '1168px'}}>
      <PageHead title="Reconnect bank accounts"/>
      <Card>
        <MigrationDescription isFromBNZ={isFromBNZ}/>
        <ToMigrateSection
          filterComboboxItems={filterComboboxItems}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          accountData={accountData}
          ref={migrateSelectionRef}
        />
      </Card>
      <ButtonRow className="animate">
        <Button
          className="try-skip-migration"
          style={{marginBottom: '20px'}}
          type="secondary"
          onClick={() => setShowDoItLaterModal(true)}
        >
          I'll do it later
        </Button>
        <Button
          style={{marginBottom: '20px'}}
          type="primary"
          onClick={handleSaveSelection}
        >
          Save selection
        </Button>
      </ButtonRow>
      {showMigrationModal && <ConfirmationModal setShowMigrationModal={setShowMigrationModal} isFromBNZ={isFromBNZ} handleMigrate={handleMigrate} />}
      {showDoItLaterModal && <DoItLaterModal setShowDoItLaterModal={setShowDoItLaterModal} handleRedirect={handleRedirect} />}
    </div>
  )
}
export default FeedMigrationView;
