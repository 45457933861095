import {Button, Modal} from "@myob/myob-widgets";
import React from "react";

export default function DoItLaterModal({setShowDoItLaterModal, handleRedirect}) {
  return <Modal size="large"
                style={{width: '544px', top: 'calc(50vh - 221px)'}}
                title="Are you sure you want to do this later?"
                onCancel={() => setShowDoItLaterModal(false)}>

    <Modal.Body>
      <p>
        Any selections you have made will be discarded and has to be done at a later time.
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button
        tone="neutral"
        variant="standard"
        className="btn btn-default skip-migration"
        onClick={() => {
          setShowDoItLaterModal(false)
          handleRedirect()
        }}
      >
        Yes, I’ll do it later
      </Button>
    </Modal.Footer>
  </Modal>;
}
