import * as _ from "lodash";
import {BankIcon, Combobox} from "@myob/myob-widgets";
import React, {useEffect, useState, forwardRef, useImperativeHandle} from "react";
import TableSection from "./TableSection";
import {SKIP_FOR_NOW_OPTION} from "./FeedMigrationView";

const ToMigrateSection = forwardRef(({
                                  validationErrors,
                                  setValidationErrors,
                                  accountData,
                                }, ref) => {

  const [bankAccountSelections, setBankAccountSelections] = useState({});
  const [creditCardSelections, setCreditCardSelections] = useState({});

  const bankAccountsOptions = [
    ...accountData?.consentedAccounts.bankAccounts || [],
    SKIP_FOR_NOW_OPTION
  ];
  const creditCardsOptions = [
    ...accountData?.consentedAccounts.creditCards || [],
    SKIP_FOR_NOW_OPTION
  ];

  const comboboxMetaData = [
    {columnName: 'name', columnWidth: '50%', showData: true},
    {columnName: 'number', columnWidth: '50%', showData: true},
  ];

  const initializeBankAccountSelections = (data) => {
    const initialBankAccountSelections = {};
    const bankAccounts = data.bankFeedsForMigration.bankAccounts;
    const consentedBankAccounts = data.consentedAccounts.bankAccounts;

    if (_.isEmpty(consentedBankAccounts)) {
      _.forEach(bankAccounts, account => {
        initialBankAccountSelections[account.id] = SKIP_FOR_NOW_OPTION;
      });
    } else {
      _.forEach(bankAccounts, account => {
        const matchedConsentAccount = _.find(consentedBankAccounts, consentedAccount => consentedAccount.number === account.accountNumber, 0);
        if (matchedConsentAccount && !_.chain(initialBankAccountSelections).values().includes(matchedConsentAccount).value()) {
          initialBankAccountSelections[account.id] = matchedConsentAccount;
        }
      });

      if (_.values(initialBankAccountSelections).length === consentedBankAccounts.length) {
        _.forEach(bankAccounts, account => {
          if (!initialBankAccountSelections[account.id]) {
            initialBankAccountSelections[account.id] = SKIP_FOR_NOW_OPTION;
          }
        });
      }
    }

    return initialBankAccountSelections;
  };
  const initializeCreditCardSelections = (data) => {
    const initialCreditCardSelections = {};

    if (_.isEmpty(data.consentedAccounts.creditCards)) {
      _.forEach(data.bankFeedsForMigration.creditCards, card => {
        initialCreditCardSelections[card.id] = SKIP_FOR_NOW_OPTION;
      });
    }

    return initialCreditCardSelections;
  };

  useEffect(()=> {
    setBankAccountSelections(initializeBankAccountSelections(accountData));
    setCreditCardSelections(initializeCreditCardSelections(accountData));
  }, [accountData])

  useImperativeHandle(ref,  () => ({
    bankAccountSelections,
    creditCardSelections
  }));

  const filterComboboxItems = (allItems, selectedItems, currentRowId) =>
    _.filter(allItems, item =>
      _.isEmpty(item.number) || !_.some(selectedItems, (selected, key) => selected?.number === item.number && key !== currentRowId)
    );

  const onBlurValidate = (selectedItems, rowId) => {
    setValidationErrors(prev => getUpdatedValidationErrorsWithAutoFill(selectedItems, prev, rowId));
  };

  function handleBankAccountSelect(item, rowId) {
    setBankAccountSelections(prev =>
      getUpdatedSelectionWithAutoFill(accountData.bankFeedsForMigration.bankAccounts, prev, rowId, item, bankAccountsOptions)
    );
  }
  const handleCreditCardSelect = (item, rowId) => {
    setCreditCardSelections(prev => getUpdatedSelectionWithAutoFill(accountData.bankFeedsForMigration.creditCards, prev, rowId, item, creditCardsOptions))
  };

  const getUpdatedSelectionWithAutoFill = (allRows, currentSelections, currentRowId, selectionItem, comboboxOptions) => {
    const updatedSelections = {...currentSelections, [currentRowId]: selectionItem};

    _.forEach(allRows, row => {
      if (row.id !== currentRowId) {
        const filteredItems = filterComboboxItems(comboboxOptions, updatedSelections, row.id);
        if (filteredItems.length === 1) {
          updatedSelections[row.id] = filteredItems[0];
        }
      }
    });

    return updatedSelections
  };

  const getUpdatedValidationErrorsWithAutoFill = (selectedItems, currentErrors, rowId) => {
    const updatedValidationErrors = {[rowId]: !!selectedItems[rowId] ? '' : "Make a selection before continuing"}
    _.chain(selectedItems).keys().filter(key => !!selectedItems[key]).forEach(key => updatedValidationErrors[key] = '').value();
    return {...currentErrors, ...updatedValidationErrors};
  }

  function renderCombobox(row, placeholder, selectedItems, options, handleSelect ) {
    return <Combobox
      selected={selectedItems[row.id] || null}
      items={filterComboboxItems(options, selectedItems, row.id)}
      metaData={comboboxMetaData}
      onChange={(item) => handleSelect(item, row.id)}
      onBlur={() => onBlurValidate(selectedItems, row.id)}
      errorMessage={validationErrors[row.id]}
      hintText={placeholder}
      allowClear
      prefixIcon={<BankIcon/>}
      label={placeholder}
      hideLabel />
  }

  const bankAccountData = {
    handleSelect: handleBankAccountSelect,
    selectedItems: bankAccountSelections,
    headText: "Bank account",
    headStyle: {marginTop: '16px'},
    additionalInfo: null,
    tableData: accountData?.bankFeedsForMigration?.bankAccounts || [],
    options: bankAccountsOptions,
    cellSelector: (row) => renderCombobox(row, "Select BNZ account", bankAccountSelections,bankAccountsOptions,handleBankAccountSelect )
  }

  const creditCardData = {
    handleSelect: handleCreditCardSelect,
    selectedItems: creditCardSelections,
    headText: "Credit card",
    headStyle: {marginTop: '16px'},
    additionalInfo: null,
    tableData: accountData?.bankFeedsForMigration?.creditCards || [],
    options: creditCardsOptions,
    cellSelector: (row) => renderCombobox(row, "Select BNZ credit card", creditCardSelections,creditCardsOptions,handleCreditCardSelect )
  }

  return <TableSection bankAccountData={bankAccountData} creditCardData={creditCardData} showStatusColumn={false} />;
})

export default ToMigrateSection
