import React, {useEffect, useState} from "react";
import {Text, Box, Columns, Heading, Separator} from "@myob/myob-widgets";

function OpenBankingAccountResultTable({accountsData}) {
  const tableColumns = [
    {key: 'name', description: 'Account name', align: 'left', textWrap: 'wrap'},
    {key: 'number', description: 'Account number', align: 'left'},
  ];

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function renderDesktopBody() {
      return tableColumns.map(column => (
        <Columns.Column key={column.key} span={['12', '12', '6', '6', '6']}>
          <Heading level="3" marginBottom="clear">{column.description}</Heading>
          {accountsData.map(account => (
            <React.Fragment key={account.id}>
              <Separator display={['block', 'block' , 'block']}/>
              <Text marginBottom="clear" textAlign={column.align} style={{whiteSpace: column.textWrap}}>
                {account[column.key]}
              </Text>
            </React.Fragment>
          ))}
        </Columns.Column>
      ))
  }
  function renderMobileBody() {
    return accountsData.map((account, index) => (
      <>
        {index >0 && <Separator display={['block', 'block', 'block']}/> }
        {tableColumns.map((column) => (
          <React.Fragment key={account.id}>
            <Heading level="3" marginBottom="clear">{column.description}</Heading>
            <Text marginBottom="clear" textAlign={column.align} style={{whiteSpace: column.textWrap}}>
              {account[column.key]}
            </Text>
          </React.Fragment>
        ))}
      </>
    ))
  }
  return (
    <Box
      borderColor="default"
      borderRadius="lg"
      borderStyle="solid"
      borderWidth="thin"
      textAlign="center"
      padding="lg"
      display={'flex'}
      flexDirection={["column", "column", "row", "row", "row"]}
      >
      {isDesktop ? renderDesktopBody() : renderMobileBody()}
    </Box>
  );
}

export default OpenBankingAccountResultTable;
